import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import FishBatchForm from "../components/FishBatchForm";



const ProductBatchForm = () => {
    const dispatch = useDispatch();

    const state = useSelector(state => state)
    const { auth } = state
    const userRole = _.get(auth, "authUserInfo.jwtTokenDecode.role", "")



    return (
        <>
            {/* {userRole === "Fish" && <FishBatchForm />} */}
            <FishBatchForm />
        </>
    );
};
export default ProductBatchForm;
