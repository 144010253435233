import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { StCloseButton } from "src/components/CloseButton";
import { ButtonSquare } from "src/components/ButtonSquare";
import { deleteAccount } from "src/redux/thunks/userThunk";
import { signOutAction } from "src/redux/slice/authSlice";

function ConfirmationModal(props) {
  const { t } = useTranslation("common");
  const { isShowModel, handleClose } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    handleClose();
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      await dispatch(deleteAccount()).unwrap();
      setLoading(false);
      handleCloseModal();
      dispatch(signOutAction());
      navigate("/login");
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Modal
      size="md"
      centered={true}
      backdrop="static"
      show={isShowModel}
      onHide={handleCloseModal}
    >
      <Modal.Header>
        <div style={{ position: "absolute", right: 10 }}>
          <StCloseButton
            text={t("Close")}
            onClick={() => handleCloseModal()}
          ></StCloseButton>
        </div>
      </Modal.Header>
      <Modal.Body style={{ fontWeight: "normal" }}>
        <div>{t("Are you sure you want to delete your account?")}</div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center" }}>
        <ButtonSquare
          text={t("Cancel")}
          onClick={() => handleCloseModal()}
          loading={loading}
        />
        <ButtonSquare
          text={t("Confirm")}
          onClick={() => handleConfirm()}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
