import { createSlice } from "@reduxjs/toolkit";
import { getAuthStatusAction, signinAction } from "../thunks/authThunk";
import Cookies from "js-cookie";
import { toast } from 'react-toastify';
import { t } from "src/locales/localeHelper"

const initialState = {
  loading: false,
  isAuthTokenChecked: false,
  isAuth: false,
  authUserInfo: {
    // email: "",
    jwtToken: "",
    jwtTokenDecode: "",
  },
};


const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    signOutAction: (state) => {
      state.isAuth = false;
      state.authUserInfo = initialState.authUserInfo;

      try {
        Cookies.remove("jwtToken")
        Cookies.remove("refreshToken")
      } catch (error) {

      }
    },
  },

  extraReducers: 
  
  (builder) => {
    builder
      
    
    .addCase(getAuthStatusAction.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getAuthStatusAction.fulfilled, (state, action) => {
      if (action.payload) {
        const { jwtToken, jwtTokenDecode } = action.payload;
        state.isAuth = true;
        state.isAuthTokenChecked = true;
        state.authUserInfo.jwtToken = jwtToken;
        state.authUserInfo.jwtTokenDecode = jwtTokenDecode;
      } else {
        state.isAuth = false;
        state.authUserInfo = initialState.authUserInfo;
      }
      state.isLoading = false;
    })
    .addCase(getAuthStatusAction.rejected, (state) => {
      state.isLoading = false;
      state.isAuthTokenChecked = true;
      state.isAuth = false;
      state.authUserInfo = initialState.authUserInfo;
    })



    .addCase(signinAction.pending, (state) => {
      state.loading = true;
    })
    .addCase(signinAction.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        const { jwtToken, jwtTokenDecode } = action.payload;
        state.isAuth = true;
        state.authUserInfo.jwtToken = jwtToken;
        state.authUserInfo.jwtTokenDecode = jwtTokenDecode;
        toast.success(t("nofn-msg-to-login"), "", 2000);
      } else {
        state.isAuth = false;
        state.authUserInfo = initialState.authUserInfo;
      }
    })
    .addCase(signinAction.rejected, (state) => {
      state.loading = false;
      setTimeout(() => {
        toast.error("Login failed", "Information", 2000);
      }, 10);
    })
    
  },
});

export const { signOutAction } = authSlice.actions

export default authSlice;
