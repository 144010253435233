import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  getAllUsersData,
  addUserAction,
  getSpecificUserDetailsById,
  updateUserById,
  getSpecificUserDevices,
  deleteSpecificUserByCustomerId,
  getUserProfile,
} from "src/redux/thunks/userThunk";

const initialState = {
  isLoading: false,
  usersData: [],
  loading: false,
  specificUserData: [],
  error: null,
  usernameError: "",
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {},
  extraReducers:
    (builder) => {
      builder

        // Get All Users Data
        .addCase(getAllUsersData.pending, (state) => {
          state.loading = true;
        })
        .addCase(getAllUsersData.fulfilled, (state, action) => {
          state.loading = false;
          state.usersData = action.payload.data;
        })
        .addCase(getAllUsersData.rejected, (state, action) => {
          state.loading = false;
        })

        //Get user Profile details
        .addCase(getUserProfile.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getUserProfile.fulfilled, (state, action) => {
          state.isLoading = false;
          state.usersData = action.payload.data;
        })
        .addCase(getUserProfile.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
        })

        // add new user action
        .addCase(addUserAction.pending, (state) => {
          state.loading = true;
        })
        .addCase(addUserAction.fulfilled, (state, action) => {
          toast.success("User Added Successfully", "Information", 2000);
          state.loading = false;
          state.usersData = action.payload.data;
        })
        .addCase(addUserAction.rejected, (state, action) => {
          state.loading = false;
          state.usernameError = action.payload.response.data;
        })

        // Get Specific user details by id
        .addCase(getSpecificUserDetailsById.pending, (state) => {
          state.loading = true;
        })
        .addCase(getSpecificUserDetailsById.fulfilled, (state, action) => {
          state.loading = false;
          state.specificUserData = action.payload.data;
        })
        .addCase(getSpecificUserDetailsById.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })


        // Update Specific user by id
        .addCase(updateUserById.pending, (state) => {
          state.loading = true;
        })
        .addCase(updateUserById.fulfilled, (state, action) => {
          setTimeout(() => {
            toast.success("User Updated Successfully", "Information", 2000);
          }, 10);
          state.loading = false;
          state.usersData = action.payload.data;
        })
        .addCase(updateUserById.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })


        // Get Specific user devices details by id
        .addCase(getSpecificUserDevices.pending, (state) => {
          state.loading = true;
        })
        .addCase(getSpecificUserDevices.fulfilled, (state, action) => {
          state.loading = false;
          // state.data = action.payload;
        })
        .addCase(getSpecificUserDevices.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })


        // delete Specific User By Customer-Id
        .addCase(deleteSpecificUserByCustomerId.pending, (state) => {
          state.loading = true;
        })
        .addCase(deleteSpecificUserByCustomerId.fulfilled, (state, action) => {
          setTimeout(() => {
            toast.success("User Deleted successfully", "Information", 2000);
          }, 10);
          state.loading = false;
          // state.data = action.payload;
        })
        .addCase(deleteSpecificUserByCustomerId.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })

    }
})


export default userSlice;
