import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ProductBatchForm from "./containers/ProductBatchForm";

const ProductBatch = () => {
  const Location = useLocation();
  return (
    <>
      <div className="main-page-container overflow-auto">
        <Routes>
          <Route
            path={`/`}
            element={<Navigate replace="/" to={`${Location.pathname}/form`} />}
          ></Route>
          <Route path={"form"} element={<ProductBatchForm />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default ProductBatch;
